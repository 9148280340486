import React, { useState, useEffect, useRef } from 'react';
import Nav from "../Nav/Nav"
import * as styles from "./HeaderPC.module.scss"

const HeaderPc = ({ pageName }) => {
  const target = useRef(null);
  const [classNames, setClassNames] = useState([styles.item, styles.scroll]);
  useEffect(() => {
    const targetTopPosition = target.current.getBoundingClientRect().top;
    const showTarget = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      if (scrollPosition > targetTopPosition + 1500) {
        setClassNames([styles.item, styles.scrollShow])
      } else {
        setClassNames([styles.item, styles.scroll])
      }
    }
    showTarget();
    const onScroll = () => {
      showTarget();
    }
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div ref={target} className={classNames.join(" ")}>
      <div className={styles.Header__inner}>
        <Nav pageName={pageName} />
      </div>
    </div>
  )
}

export default HeaderPc