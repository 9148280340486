import React, { useState, useEffect, useRef } from 'react';
import * as styles from "./index.module.scss"


const ScrollComponent = ({ children }) => {

  const target = useRef(null);

  const [classNames, setClassNames] = useState([styles.item, styles.scroll]);
  useEffect(() => {

    const targetTopPosition = target.current.getBoundingClientRect().top;
    const showTarget = () => {
      const TriggerMargin = window.innerHeight * 0.3;//ターゲット要素がWindowの下から30%の位置まで来たら

      const scrollPosition = window.scrollY + window.innerHeight;
      if (scrollPosition > targetTopPosition + TriggerMargin) {
        setClassNames([styles.item, styles.scrollShow])
      } else {
        setClassNames([styles.item, styles.scroll])
      }
    }
    showTarget();
    const onScroll = () => {
      showTarget();
    }
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll, { passive: true });
  }, []);

  return (
    <div ref={target} className={classNames.join(" ")}>
      {children}
    </div>
  );
}

export default ScrollComponent