import React from "react"
const Youtube = ({ videoSrcURL, videoTitle, ...props }) => (
  <div className={props.className}>
    <iframe width={props.width} height={props.height}
      src={videoSrcURL}
      title={videoTitle}
      loading="lazy"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </div>
)
export default Youtube