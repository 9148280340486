import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import * as styles from "./CategoryMenu.module.scss"

const CategoryMenu = ({ catSlug }) => {
  const data = useStaticQuery(//ページコンポーネント以外ではuseStaticQueryを利用してgraphqlが利用できる
    graphql`
    query {
      allWpCategory {
          edges{
            node {
              name
              slug
              link
              posts{
                nodes{
                  slug
                }
              }
          }
        }
      }
    }
  `)
  const catNodes = data.allWpCategory.edges;
  // const catPosts = data.allWpCategory.edges[0].node.posts.nodes.length
  // console.log(catPosts);
  return (
    <div className={styles.categoryMenu}>
      <ul>
        <li className={`catAll`}>
          <a href="/news/">すべて</a>
        </li>
        {catNodes.map(({ node }) => (
          node.posts.nodes.length > 0 ? //投稿があるかチェック
            //カテゴリslugをチェック
            node.slug === catSlug ?
              <li key={node.slug} className={`${node.slug} ${styles.categoryMenu__current}`}>
                <a href={'/categories/' + node.slug}>
                  {node.name}
                </a>
              </li>
              :
              <li key={node.slug} className={`${node.slug}`}>
                <a href={'/categories/' + node.slug}>
                  {node.name}
                </a>
              </li>
            :
            null
        ))}
      </ul>
    </div>
  )
}

export default CategoryMenu