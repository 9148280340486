import React, { useState } from 'react';
import * as styles from "./SimpleAccordion.module.scss";

const SimpleAccordion = ({ className, children, ...props }) => {
  const [setActive, setActiveState] = useState(styles.hidden);
  const toggleAccordion = () => {
    setActiveState(
      setActive !== styles.show ?
        styles.show
        :
        styles.hidden
    );
  };
  /**
   * buttonを下にするとなぜかクリックできなくなる。flexでorderを指定しても同様。
   * position:absoluteではクリック可能。2021-08-23 要調査
   * 2021-08-23 margin部分が上にかぶさっていることでクリックできない模様。
   */
  return (
    <div className={className}>
      <div className={className + "__inner " + setActive}>
        {children}
      </div>
      <button onClick={toggleAccordion} className={className + "__triger " + setActive}>
        <span>
          {
            setActive !== styles.show ?
              props.trigerShow
              :
              props.triger
          }
        </span>
      </button>
    </div>
  )
}
export default SimpleAccordion