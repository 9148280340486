import React from "react"
// import { Link } from "gatsby"
import * as styles from "./LinkButton.module.scss"

const LinkButton = ({ children, link, target }) => {
  return (
    <div className={styles.LinkBtn}>
      {/* <Link to={link || '/#'}> */}
      <a href={link} target={target}>
        {children}
      </a>
    </div>
  )
}

export default LinkButton;