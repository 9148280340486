import React from "react"
import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { Button } from "../index"//サイト全体のコンポーネント
import * as styles from "./FltShopBtn.module.scss"

const FltShopBtn = () => {
  const breakpoints = useBreakpoint();

  return (
    <>
      {breakpoints.sm ?
        // <Link className="FltShopBtnSp" to="http://www.chosyudori.net/" target="_blank">
        <a className={styles.FltShopBtnSp} href="http://www.chosyudori.net/" target="_blank" rel="noreferrer">
          <StaticImage
            src="../../images/icon/icon_shop-sp.png"
            alt="Online Shop"
            placeholder="tracedSVG"
          />
        </a>
        // </Link>
        :
        <Button className={styles.FltShopBtn} onClick={() => window.open('http://www.chosyudori.net/', '_blank')}>
          Online Shop
        </Button>
      }
    </>
  )
}
export default FltShopBtn