import React from 'react';
import * as styles from "./Header.module.scss"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import HeaderPc from "./HeaderPc"
import HeaderSp from "./HeaderSp"

const Header = ({ pageName }) => {

  const breakpoints = useBreakpoint();

  return (
    <>
      {
        breakpoints.tb ?
          <header className={styles.Header} >
            <HeaderSp pageName={pageName} />
          </header>
          :
          <header className={styles.Header} >
            <HeaderPc pageName={pageName} />
          </header>
      }
    </>
  )

}

export default Header