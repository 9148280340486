import React, { useState, useEffect } from "react"
import { animateScroll as scroll } from "react-scroll"
import styled from 'styled-components'


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronUp } from "@fortawesome/free-solid-svg-icons"

const PageTopButton = () => {
  //-- 一番上までスクロールする
  const scrollToTop = () => {
    scroll.scrollToTop()
  }
  //---
  /**
   * スクロールの方向を検出する
   */
  const [scrollDir, setScrollDir] = useState("scrolling down");
  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;
    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;
      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? "scrolling down" : "scrolling up");
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };
    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll, { passive: true });
  }, [scrollDir]);
  //---
  /**
   * Windowの一番上と一番下に来たときを検出したい
   */

  const [winPos, setWinPos] = useState("window top")

  useEffect(() => {
    var docHeight = document.body.clientHeight;//ページ全体の高さ
    var winHeight = window.innerHeight;//ウィンドウの高さ
    var winTop = 0;
    var winBtm = docHeight - winHeight;//ページ全体の高さ - ウィンドウの高さ = ページの最下部位置

    const detectWinPos = () => {
      if (window.pageYOffset === winTop) {
        setWinPos("window top")
        // console.log("最上部！");
      } else if (winBtm <= window.pageYOffset) {
        //一番下までスクロールした時に実行
        setWinPos("window bottom")
        // console.log("最底辺！");
      } else {
        setWinPos("scrolling");
        // console.log("スクロール中");
      }
    }
    window.addEventListener("scroll", detectWinPos);
    return () => window.removeEventListener("scroll", detectWinPos);
  }, [winPos]);



  return (
    <Div className="pagetop" winPos={winPos} scrollDir={scrollDir}>
      <Button name={`pageTop`} className="pagetop__btn" onClick={() => scrollToTop()}><FontAwesomeIcon icon={faChevronUp} /><span>ページトップへ</span></Button>
    </Div>
  )
}
export default PageTopButton

const Div = styled.div`
position: fixed;
right: 10px;
bottom: 30px;
width: 44px;
height: 44px;
transition: opacity 300ms ease-in-out;
${({ winPos, scrollDir }) => `
  opacity:${winPos === "window top" || (scrollDir === "scrolling down" && winPos !== "window bottom") ? 0 : 1};
  z-index:${winPos === "window top" || (scrollDir === "scrolling down" && winPos !== "window bottom") ? -1 : 11};
`}
`

const Button = styled.button`
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: #b5b5b5;
  border-radius: 50%;
  span{
    width: 0;
    height: 0;
    color: transparent;
    opacity: 0;
    font-size: 0;
  }
`