import React from "react"
import "./TargetBlankIcon.scss"

const TagetBlankIcon = () => {
  return (
    <span id="_107">
      <svg className="_158_m" viewBox="496.827 635.308 5.576 5.576">
        <path id="_158_m" d="M 502.0043640136719 635.3079833984375 L 498.4195251464844 635.3079833984375 C 498.1996765136719 635.3079833984375 498.0212097167969 635.486083984375 498.0212097167969 635.706298828125 C 498.0212097167969 635.9262084960938 498.1996765136719 636.1046142578125 498.4195251464844 636.1046142578125 L 501.0428161621094 636.1046142578125 L 496.9429626464844 640.2044677734375 C 496.7876892089844 640.3598022460938 496.7876892089844 640.6119384765625 496.9429626464844 640.7677001953125 C 497.0206604003906 640.8453369140625 497.1226501464844 640.8843383789062 497.2246398925781 640.8843383789062 C 497.3266296386719 640.8843383789062 497.4285583496094 640.8453369140625 497.5061950683594 640.7677001953125 L 501.6060485839844 636.6679077148438 L 501.6060485839844 639.2911376953125 C 501.6060485839844 639.510986328125 501.7844543457031 639.689453125 502.0043640136719 639.689453125 C 502.2242126464844 639.689453125 502.4026794433594 639.510986328125 502.4026794433594 639.2911376953125 L 502.4026794433594 635.706298828125 C 502.4026794433594 635.486083984375 502.2242126464844 635.3079833984375 502.0043640136719 635.3079833984375 Z">
        </path>
      </svg>
      <svg className="_159_na" viewBox="486.826 639.308 7.966 7.966">
        <path id="_159_na" d="M 494.3938903808594 643.2911376953125 C 494.1740417480469 643.2911376953125 493.9955749511719 643.4691162109375 493.9955749511719 643.689453125 L 493.9955749511719 646.4775390625 L 487.6225891113281 646.4775390625 L 487.6225891113281 640.1046142578125 L 490.4107971191406 640.1046142578125 C 490.6307067871094 640.1046142578125 490.8091125488281 639.9261474609375 490.8091125488281 639.706298828125 C 490.8091125488281 639.486083984375 490.6307067871094 639.3079833984375 490.4107971191406 639.3079833984375 L 487.2243347167969 639.3079833984375 C 487.0044250488281 639.3079833984375 486.8260192871094 639.486083984375 486.8260192871094 639.706298828125 L 486.8260192871094 646.8758544921875 C 486.8260192871094 647.095703125 487.0044250488281 647.274169921875 487.2243347167969 647.274169921875 L 494.3938903808594 647.274169921875 C 494.6138000488281 647.274169921875 494.7922058105469 647.095703125 494.7922058105469 646.8758544921875 L 494.7922058105469 643.689453125 C 494.7922058105469 643.4691162109375 494.6138000488281 643.2911376953125 494.3938903808594 643.2911376953125 Z">
        </path>
      </svg>
    </span>
  )
}
export default TagetBlankIcon