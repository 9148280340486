import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components'
import * as styles from "./TitleAnimation.module.scss"

const TitleAnimation = ({ className, data }) => {

  const target = useRef(null);

  const [classNames, setClassNames] = useState([styles.item, styles.scroll]);
  useEffect(() => {

    const targetTopPosition = target.current.getBoundingClientRect().top;
    const showTarget = () => {
      const TriggerMargin = window.innerHeight * 0.3;//ターゲット要素がWindowの下から30%の位置まで来たら

      const scrollPosition = window.scrollY + window.innerHeight;
      if (scrollPosition > targetTopPosition + TriggerMargin) {
        setClassNames([styles.item, styles.scrollShow])
      } else {
        setClassNames([styles.item, styles.scroll])
      }
    }
    showTarget();
    const onScroll = () => {
      showTarget();
    }
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll, { passive: true });
  }, []);

  return (
    <Div ref={target} className={className + ' ' + classNames.join(" ")}>
      {data.map((title, i) => (
        <Span key={i} index={i}>{title.text !== "" ? title.text : " "}</Span>
      ))}
    </Div>
  )
}
export default TitleAnimation

const Div = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  justify-content: flex-start;
`

const INTERVAL_SEC = 0.2;
const Span = styled.span`
  display: block;
  transform: translate(0, 105%);
  transition: transform cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
  font-family: "Rubik";
  min-width: 0.3em;
  ${({ index }) => `
    transition-delay: ${INTERVAL_SEC * index}s;
  `}
`