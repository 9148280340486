import React from "react"

const ContactInfo = ({ className }) => {
  return (
    <dl className={className}>
      <dt>深川養鶏農業協同組合</dt>
      <dd>
        〒759-4101<br />
        山口県長門市東深川1859-1<br />
        TEL : (0837)22-2121 <br />
        FAX : (0837)22-5432
      </dd>
    </dl>
  )
}
export default ContactInfo