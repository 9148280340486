import React from "react"
// import { Link } from "gatsby"
import "./Nav.scss";
import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import TargetBlankIcon from '../TargetBlankIcon/TargetBlankIcon'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"


const menuItems = [
  { id: 0, text: "ホーム", subText: "home", link: "/", target: "" },
  { id: 1, text: "お知らせ", subText: "news", link: "/news/", target: "" },
  { id: 2, text: "事業内容", subText: "business", link: "/business/", target: "" },
  { id: 3, text: "会社案内", subText: "company", link: "/companyoutline/", target: "" },
  { id: 4, text: "商品案内", subText: "products", link: "/product/", target: "" },
  { id: 5, text: "採用情報", subText: "recruit", link: "/recruit/", target: "" },
  { id: 6, text: "ショップ", subText: "shop", link: "//www.chosyudori.net/", target: "_blank" },
];

const Nav = ({ pageName }) => {
  const breakpoints = useBreakpoint();
  const MobileSnsText = () => {
    return (
      <p className={`Nav__snsLead`}>SNSにて情報発信中</p>
    )
  }
  return (
    <nav className={`Nav`}>
      <ul className={`Nav__list`}>
        {menuItems.map((menuItem, i) => (
          <li key={i} className={`Nav__listItem`} data-current={(pageName === menuItem.subText) ? true : false} >
            {/* <Link to={menuItem.link} target={menuItem.target} className={`Nav__link`}> */}
            <a href={menuItem.link} target={menuItem.target} className={`Nav__link`} rel="noreferrer">
              <strong>{menuItem.text}</strong>
              <small>{menuItem.subText}{menuItem.target === "_blank" ? <TargetBlankIcon /> : null}</small>
              {breakpoints.tb && pageName !== "footer" ? <span className={`iconWrp`}><FontAwesomeIcon icon={faChevronRight} /></span> : null}
            </a>
            {/* </Link> */}
          </li>
        ))}
        <li className={`Nav__listItem`}>
          <div className={`Nav__sns`}>
            {breakpoints.tb || pageName === "footer" ? <MobileSnsText /> : null}
            {/* <Link to="https://www.facebook.com/chosyudori/" target="_blank" className={`Nav__fb`}> */}
            <a href="https://www.facebook.com/chosyudori/" target="_blank" className={`Nav__fb`} rel="noreferrer">
              <StaticImage
                src="../../images/icon/icon_facebook.png"
                alt="facebook"
                placeholder="tracedSVG"
              />
            </a>
            {/* </Link> */}
            {/* <Link to="/#" target="_blank" className={`Nav__insta`}> */}
            <a href="https://www.instagram.com/fukawa.chicken/" target="_blank" className={`Nav__insta`} rel="noreferrer">
              <StaticImage
                src="../../images/icon/icon_instagram.png"
                alt="instagram"
                placeholder="tracedSVG"
              />
            </a>
            {/* </Link> */}
          </div>
        </li>
      </ul>
    </nav>
  )
}

export default Nav