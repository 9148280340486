import React, { useState } from "react"
import Modal from "react-modal";
import * as styles from "./HeaderSp.module.scss"
import styled from 'styled-components'
import { Nav, Youtube, ContactInfo } from "../index"
// import { Nav, Movie, ContactInfo } from "../index"
import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる

// react-modalの初期設定
// root nodeのSelectorを設定
Modal.setAppElement("#___gatsby");

const modalStyle = {
  overlay: {
    zIndex: 15,
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    width: "100vw",
    height: "100vh"
  },
  content: {
    width: "100vw",
    height: "100vh",
    inset: 0,
    padding: 0,
    overflowX: "hidden",
    overflowY: "scroll",
    border: "none",
    backgroundColor: "#FFF"
  }
}

const HeaderSp = ({ pageName }) => {

  // モーダルの表示状態と切り替える為にState(props)を準備
  const [modalIsOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button isOpen={modalIsOpen} onClick={() => setIsOpen(!modalIsOpen)}>
        <LineWrap>
          <Line isOpen={modalIsOpen}></Line>
          <Line isOpen={modalIsOpen}></Line>
          <Line isOpen={modalIsOpen}></Line>
        </LineWrap>
        <strong>{modalIsOpen ? "close" : "menu"}</strong>
      </Button>
      <Modal
        style={modalStyle}
        isOpen={(modalIsOpen === true)}
        onRequestClose={() => setIsOpen()}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
      >
        <div className={styles.logo}>
          <StaticImage
            width={45.5}
            src="../../images/footer_logo.png"
            alt="logo"
            placeholder="tracedSVG"
          />
        </div>
        <Nav pageName={pageName} />
        <div className={styles.MenuBtm}>
          <div className={styles.YoutubeCaption}>
            <span>深川養鶏協同組合 紹介movie</span>
          </div>
          <Youtube
            className={styles.YoutubeWrp}
            width="100%"
            height="100%"
            videoSrcURL="https://www.youtube.com/embed/LUGFNwW1QpY"
            videoTitle="深川養鶏農業協同組合　 企業ＰＲ動画"
          />
          {/* <Movie className={`${styles.YoutubeWrp}`} /> */}
          <ContactInfo className={styles.ContactInfo} />
        </div>
      </Modal>
    </>
  )
}
export default HeaderSp

const Button = styled.button`
  display: block;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 75px;
  border-radius: 0 0 0 25px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 25;
  >strong{
    font-size: 12px;
    font-family: "Rubik";
    color: #EEB718;
    margin-top: 7px;
  }
  ${({ isOpen }) => `
    background-color: ${isOpen ? "#EEB718" : "#fff"};
    >strong{
      color: ${isOpen ? "#fff" : "#EEB718"};
    }
  `}
`
const LineWrap = styled.span`
  position: relative;
  display: block;
  width: 24px;
  height: 21px;
`

const Line = styled.span`
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 3px;
  position: absolute !important;
  left: 0;
  transform-origin: center;
  :first-child{
    top: 0px;
  }
  :nth-child(2){
    top: 9px;
  }
  :nth-child(3){
    bottom: 0;
  }
  position: relative;
  transition: transform 300ms;
  ${({ isOpen }) => `
    background-color: ${isOpen ? "#fff" : "#EEB718"};
    :first-child{
      transform: translate(${isOpen ? "0px,9px" : "0px"}) rotate(${isOpen ? "-45deg" : " 0deg"});
    }
    :nth-child(2){
      opacity:${isOpen ? "0" : "1"};
    }
    :nth-child(3){
      transform: translate(${isOpen ? "0px,-9px" : "0px"}) rotate(${isOpen ? "45deg" : " 0deg"}) ;
    }
  `}

`