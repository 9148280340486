import React from "react"
import * as styles from "./FooterBg.module.scss"

const FooterBg = () => {
  return (
    <div className={styles.id_bg05}>
      <svg className={styles.bg05_m} preserveAspectRatio="none" viewBox="0 5959.267 1600 51.478">
        <path className={styles.id_bg05_m} d="M 0 6010.7451171875 L 1600 6010.7451171875 L 1600 5959.26708984375 C 1375.298950195312 5981.5322265625 1041.387939453125 6001.26123046875 659.5120239257812 5980.30712890625 C 374.9049987792969 5964.68896484375 156.0659942626953 5972.7138671875 0 5986.66015625 L 0 6010.7451171875 Z">
        </path>
      </svg>
    </div>
  )
}
export default FooterBg