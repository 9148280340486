import React from "react"
import { Helmet } from "react-helmet"
// import Header from "../Header/Header"
// import Footer from "../Footer/Footer"
import { RakuuruHeader,RakuuruFooter } from "../index";
// import "../../styles/style.scss"
// import "@wordpress/block-library/build-style/style.css"
// import "@wordpress/block-library/build-style/theme.css"
// import { PageTopButton } from "../index"

const RakuuruLayout = ({ pageName, className, children, title }) => {
  return (
    <>
      <Helmet>
        <link rel="dns-prefetch" href="//fonts.googleapis.com/" />
        <link rel="dns-prefetch" href="//fonts.gtatic.com/" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <script defer src="https://polyfill.io/v3/polyfill.min.js?features=fetch%2CPromise"></script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-K6QFXMKTP9"></script>
        <script>
          {
            `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
  
            gtag('config', 'G-K6QFXMKTP9');
            `
          }
        </script>
      </Helmet>
      <div className={`LayoutWrapper`}>
        <RakuuruHeader />
        <main className={className}>
          {children}
        </main>
        <RakuuruFooter pageName={pageName} title={title} />
      </div>
    </>
  )
}

export default RakuuruLayout