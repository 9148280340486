import React from "react"
import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import { Link, graphql } from "gatsby"
import "./Footer.scss"
import { Nav, ContactInfo } from "../index"
import FooterBg from "./FooterBg"

const Footer = ({ pageName, ...props }) => {
  return (
    <footer className={`footer`}>
      {
        (pageName !== "home") ?
          <nav className={`Pankuzu`} >
            <ol>
              <li><a href="/">home</a></li>
              <li><span>{props.title}</span></li>
            </ol>
          </nav>
          :
          null
      }
      <FooterBg />
      <div className={`footer__inner`}>
        <div className={`footer__left`}>
          <Link to="/">
            <StaticImage
              width={80}
              src="../../images/footer_logo.png"
              alt="Fukawa"
              placeholder="tracedSVG"
            />
          </Link>
          <ContactInfo className={`ContactInfo`} />
        </div>
        <div className={`footer__right`}>
          <Nav pageName={`footer`} />
          <ul className={`footer_nav`}>
            <li><a href="/privacy/">プライバシーポリシー </a></li>
            <li><a href="/contact/">お問い合わせ </a></li>
            <li><a href="/companyoutline/#access">アクセス </a></li>
          </ul>
        </div>
      </div>
      <div className={`footer__btm`}>
        <small>Copyright © FUKAWA YOUKEI All Rights Reserved.</small>
      </div>
    </footer>
  )
}

export default Footer