import React from 'react'
import './Button.scss'

const Button = ({ className, onClick, children }) => {
  return (
    <div className={className}>
      <button className={`Button`} onClick={onClick}>{children}</button>
    </div>
  )
}
export default Button