import React from "react";
import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import * as styles from "./RakuuruFooter.module.scss"
import ContactInfo from '../ContactInfo/ContactInfo'

const RakuuruFooter = ({ pageName, ...props }) => {
  return(
    <footer className={`${styles.RakuuruFooter}`}>
      <div className={`${styles.RakuuruFooter__top}`}>
        <a href="/" className={`${styles.RakuuruFooter__logo}`}>
          <StaticImage
            layout="fullWidth"
            src="../../images/footer_logo.png"
            alt="logo_footer"
            placeholder="none"
          />
        </a>
        <ContactInfo className={styles.RakuuruFooter__info}/>
      </div>
      <div className={`${styles.RakuuruFooter__btm}`}>
        <small>Copyright © FUKAWA YOUKEI All Rights Reserved.</small>
      </div>
    </footer>
  )
}

export default RakuuruFooter