import React from "react";
import { navigate } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import _ from "lodash";
// import * as styles from "./Pagenation.module.scss";
// import "./Pagenation.scss"
import Arrow from "../../images/news/news_arrow.png"
import ArrowSp from "../../images/news/news_arrow-sp.png"

const useStyles = makeStyles(theme => ({
  root: {
    display: `flex`,
    flexWrap: `wrap`,
    justifyContent: `center`,
    alignItems: "center",
    margin: "0 auto 70px",
    [theme.breakpoints.up(480)]: {
    margin: "0 auto 120px",
    },
  },
  nav: {
    "& li": {
      margin : `0 10px`,
      [theme.breakpoints.up(480)]: {
        margin : `0 15px`,
      },
      "&:first-child": {
        "& svg":{
          opacity: "0",
        },
        "& button":{
          background: `url(${ArrowSp}) no-repeat center center`,
          backgroundSize: `cover`,
          [theme.breakpoints.up(480)]: {
            background: `url(${Arrow}) no-repeat center center`,
            backgroundSize: `cover`,
          }
        }
      },
      "&:last-child": {
        transform: `rotate(-180deg)`,
        "& svg":{
          opacity: "0",
        },
        "& button":{
          background: `url(${ArrowSp}) no-repeat center center`,
          backgroundSize: `cover`,
          [theme.breakpoints.up(480)]: {
            background: `url(${Arrow}) no-repeat center center`,
            backgroundSize: `cover`,
          }
        }
      }
    },
    "& .MuiButtonBase-root" :{
      backgroundColor:`#B5B5B5`,
      color:`#fff`,
      border: `none`,
    },
    "& .Mui-selected": {
      backgroundColor:`#D4161A`,
    },
  },
}));

const Pagenation = ({ pageContext }) => {
  const classes = useStyles();
  const { numPages, currentPage, category } = pageContext;

  const handleChange = (event, value) => {
    if (category !== undefined) {
      value === 1 ? navigate(`/categories/${_.kebabCase(category)}`) : navigate(`/categories/${_.kebabCase(category)}/${value}`);
    } else {
      value === 1 ? navigate(`/news`) : navigate(`/news/${value}`);
    }
  };
  return (
    <div className={classes.root}>
        <Pagination
          size="small"
          defaultPage={currentPage}
          count={numPages}
          color="secondary"
          variant="outlined"
          onChange={handleChange}
          className={classes.nav}
        />
    </div>
  );
};
export default Pagenation;