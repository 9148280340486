import React from 'react';
import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import * as styles from './RakuuruHeader.module.scss'

const RakuuruHeader = () => {
  return(
    <header className={styles.RakuuruHeader}>
      <a href="/">
          <StaticImage
            layout="fullWidth"
            src="../../images/footer_logo.png"
            alt="logo_footer"
            placeholder="none"
          />
        </a>
    </header>
  )
}

export default RakuuruHeader